import { useContext } from 'react'
import { HeaderContext } from '../../contexts'
import { columns } from '../../styles/grid'
import Window from 'utils/Window'

const isBrowser = typeof window !== 'undefined'

const useRootMargin = () => {
  const headerRef = useContext(HeaderContext)

  if (isBrowser) {
    const headerMargin = Window.innerWidth < 1024 ? Window.innerWidth / (columns / 2) : Window.innerWidth / columns

    return headerRef.current
      ? `-${headerMargin}px 0px -${Window.innerHeight - (headerRef.current.clientHeight - headerMargin / 2)}px 0px`
      : '0px'
  }

  return '0px'
}

export default useRootMargin
