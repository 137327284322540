import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setHeaderColor } from '../actions'

const useHeaderColor = (inView, colorsIn, colorsOut) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (inView) {
      dispatch(setHeaderColor(colorsIn))
    } else {
      dispatch(setHeaderColor(colorsOut))
    }
  }, [inView])
}

export default useHeaderColor
