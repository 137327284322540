import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import countryList from '../../static/countryList.json'
import { useInView } from 'react-intersection-observer'
import Select from 'react-select'
import { SiteLink } from '../SiteLink'
import RichText from '../RichText'
import useRootMargin from '../../hooks/window/useRootMargin'
import useHeaderColor from '../../hooks/useHeaderColor'
import { DictionaryFormContext, LayoutContext } from '../../contexts'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  border,
  underlineMultiLines,
  zIndex,
  marquee
} from '../../styles'

const ContactForm = ({ contacts, contactStripText }) => {
  const dictionaryForm = useContext(DictionaryFormContext)
  const { node_locale, privacyLinkForms } = useContext(LayoutContext)
  const rootMargin = useRootMargin()
  const [refContactForm, inViewContactForm] = useInView({ rootMargin })

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [subject, setSubject] = useState('')
  const [country, setCountry] = useState('')
  const [message, setMessage] = useState('')
  const [privacy, setPrivacy] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useHeaderColor(
    inViewContactForm,
    {
      left: color.black,
      center: color.black,
      right: color.balck
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: color.yellow,
      border: 'none',
      height: '100%',
      minHeight: 'auto'
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0'
    }),
    placeholder: (styles) => ({
      ...styles,
      color: color.black
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: color.white,
        color: color.black
      }
    }
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = re.test(String(email).toLowerCase())
    setInvalidEmail(!valid)
    return valid
  }

  const submitEmail = async (e) => {
    e.preventDefault()
    setSubmitted(true)
    if (validateEmail(email) && name && subject && message && privacy) {
      setSubscribed(true)
      setTimeout(() => document.getElementById('contact-form').submit(), 1000)
    } else if (!validateEmail(email)) {
      setInvalidEmail(true)
    }
  }

  useEffect(() => {
    if (invalidEmail) setTimeout(() => setInvalidEmail(false), 2000)
  }, [invalidEmail])

  return (
    <StyledContact>
      <FormTitle>{dictionaryForm.titleContact}</FormTitle>
      <StyledContactText>
        <ContactsWrapper>
          <Marquee>
            <p>
              <span>•</span>
              <span>{contactStripText}</span>
              <span>•</span>
              <span>{contactStripText}</span>
            </p>
            <p>
              <span>•</span>
              <span>{contactStripText}</span>
              <span>•</span>
              <span>{contactStripText}</span>
            </p>
          </Marquee>

          <RichText json={JSON.parse(contacts.contacts)} />
        </ContactsWrapper>
      </StyledContactText>

      <StyledContactForm>
        <StyledForm
          id='contact-form'
          onSubmit={(e) => submitEmail(e)}
          ref={refContactForm}
          method='POST'
          action='https://freedamedia--superfluid.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
        >
          <input type='hidden' name='orgid' value='00D0Y000001f7UA' />
          <input type='hidden' name='retURL' value={`https://superfluid.netlify.app/${node_locale !== 'en' ? `${node_locale}/` : '/'}`} />
          {!subscribed && !invalidEmail && (
            <>
              <FormHeader>
                <p>{dictionaryForm.fillTheFields}</p>
                <p>* {dictionaryForm.mandatoryFields}</p>
              </FormHeader>
              <StyledInput
                id='email'
                name='email'
                placeholder='email *'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autocomplete='off'
                empty={submitted && !email}
              />
              <label htmlFor='email' aria-label='email' />
              <StyledInput
                id='name'
                name='name'
                placeholder={`${dictionaryForm.nameUser} *`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                autocomplete='off'
                empty={submitted && !name}
              />
              <label htmlFor='name' aria-label='name' />
              <StyledInput
                id='00N0Q000001QLMP'
                name='00N0Q000001QLMP'
                placeholder={`${dictionaryForm.orderNumber}`}
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                autocomplete='off'
              />
              <label htmlFor='00N0Q000001QLMP' aria-label='order-number' />
              <StyledSelect
                id='00N0Q000001QLMF'
                name='00N0Q000001QLMF'
                placeholder={`${dictionaryForm.country}`}
                options={countryList.data}
                onChange={(e) => setCountry(e.label)}
                autocomplete='off'
                styles={selectStyles}
                aria-label='country'
              />

              <StyledInput
                id='subject'
                name='subject'
                placeholder={`${dictionaryForm.subjectEmail} *`}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                autocomplete='off'
                empty={submitted && !subject}
              />
              <label htmlFor='subject' aria-label='subject' />
              <StyledTextBox
                id='message'
                name='description'
                placeholder={`${dictionaryForm.message} *`}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                autocomplete='off'
                empty={submitted && !message}
              />

              <label htmlFor='message' aria-label='message' />
              <StyledSubmit value='submit'>
                {dictionaryForm.submitContact}
              </StyledSubmit>
              {email && (
                <AcceptTerms
                  email={email}
                  className='form-accept-terms'
                >
                  <input
                    type='radio'
                    id='privacy'
                    value='privacy'
                    checked={privacy}
                    onClick={() => setPrivacy(!privacy)}
                    readOnly
                  />
                  <PrivacyLabel
                    htmlFor='privacy'
                    aria-label='privacy'
                    empty={submitted && !privacy}
                  >
                    <StyledLink
                      slug={privacyLinkForms.link[0].slug}
                      empty={submitted && !privacy}
                    >
                      {dictionaryForm.acceptPrivacy}
                    </StyledLink>
                  </PrivacyLabel>
                </AcceptTerms>
              )}
            </>
          )}

          {subscribed && (
            <SuccessMessage>
              {dictionaryForm.submissionSuccessContact}
            </SuccessMessage>
          )}
          {!subscribed && invalidEmail && (
            <InvalidMessage>{dictionaryForm.invalidEmail}</InvalidMessage>
          )}
        </StyledForm>
      </StyledContactForm>

    </StyledContact>
  )
}

const StyledContact = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: space-between;

    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

const FormTitle = styled.p`
  position: relative;
  display: block;
  width: 100%;
  padding-top: ${mobileVW(120)};
  font-size: ${mobileVW(80)};
  line-height: ${mobileVW(68)};
  font-weight: bold;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    top: ${desktopVW(140)};
    padding: 0 ${desktopVW(260)};
    font-size: ${desktopVW(150)};
    line-height: ${desktopVW(127)};
  }
`

const StyledContactText = styled.div`
  position: relative;
  width: 100%;
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(20)};
  padding: ${mobileVW(56)} ${mobileVW(40)} 0;

  a {
    font-weight: normal;
    ${underlineMultiLines}
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: 40%;
    top: ${desktopVW(275)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(20)};
    padding:${desktopVW(120)} 0;
    margin-bottom: ${desktopVW(275)};
  }
`

const ContactsWrapper = styled.div`
  padding-bottom: ${mobileVW(30)};

  p {
    padding: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(120)};
  }
`

const Marquee = styled.div`
  display: flex;
  font-weight: bold;
  z-index: ${zIndex.low};
  background-color: ${color.yellow};
  overflow: hidden;
  white-space: nowrap;
  font-size: ${mobileVW(10)};
  line-height: ${mobileVW(19)};
  padding-top: ${mobileVW(1)};
  margin-bottom: ${mobileVW(20)};

  p {
    ${marquee}

    span {
      margin: 0 ${mobileVW(10)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(20)};
    padding-top: ${desktopVW(1)};
    margin: 0 ${desktopVW(40)} ${desktopVW(20)} 0;

    p {
      span {
        margin: 0 ${desktopVW(10)};
      }
    }
  }
`

const StyledContactForm = styled.div`
  width: 100%;
  background: ${color.yellow};
  position: relative;
  padding: ${mobileVW(60)} ${mobileVW(45)};

  @media (min-width: ${desktopBreakpoint}) {
    width: 60%;
    background: transparent;
    top: ${desktopVW(60)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(20)};
    padding: 0;
    margin-bottom: ${desktopVW(395)};
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: ${mobileVW(30)};

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    top: ${desktopVW(280)};
    margin-top: ${desktopVW(30)};
    bottom: ${desktopVW(30)};
    height: ${desktopVW(754)};
    width: ${desktopVW(719)};
    margin: 0 ${desktopVW(120)} 0 auto;
    background: ${color.yellow};
    padding: ${desktopVW(60)};

    #order-number,
    #country {
      width: calc(50% - ${desktopVW(20)});
      height: ${desktopVW(37)};
      margin-bottom: ${desktopVW(20)};
    }

    #order-number {
      margin-right: ${desktopVW(20)};
    }
    #country {
      margin-left: ${desktopVW(20)};
    }
  }
`

const FormHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${mobileVW(20)};

  p:first-child {
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(26)};
    margin-bottom: ${mobileVW(8)};
    text-transform: initial;
  }

  p:last-child {
    font-size: ${mobileVW(12)};
    line-height: ${mobileVW(24)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVW(20)} 0;
    flex-direction: row;
    align-items: baseline;

    p:first-child {
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(26)};
      margin-bottom: 0;
    }

    p:last-child {
      font-size: ${desktopVW(10)};
      line-height: ${desktopVW(8)};
    }
  }
`

const StyledInput = styled.input`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  font-weight: bold;
  border: none;
  border-bottom: ${border.medium};
  background-color: transparent;
  width: 100%;
  margin-bottom: ${mobileVW(20)};

  &::placeholder {
    color: ${color.black};
  }

  ${({ empty }) => empty ? `
    color: ${color.red};
    border-bottom: 2px solid ${color.red};

    &::placeholder {
      color: ${color.red};
    }
  ` : null}

  &:focus {
    outline: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    margin-bottom: ${desktopVW(33)};
    padding-left: 0;
  }
`

const StyledSelect = styled(Select)`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  height: ${mobileVW(34)};
  font-weight: bold;
  border: none;
  border-bottom: ${border.medium};
  width: 100%;
  margin-bottom: ${mobileVW(20)};

  div:first-child {
    svg {
      fill: ${color.black};
    }

    span {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    height: ${desktopVW(37)};
    margin-bottom: ${desktopVW(33)};
    padding-left: 0;
  }
`

const StyledTextBox = styled.textarea`
  resize: none;
  border: ${border.medium};
  background-color: transparent;
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(24)};
  font-weight: bold;
  width: 100%;
  height: ${mobileVW(166)};
  margin-bottom: ${mobileVW(20)};
  padding: ${mobileVW(12)};

  &::placeholder {
    color: ${color.black};
  }

  ${({ empty }) => empty ? `
    color: ${color.red};
    border: 2px solid ${color.red};

    &::placeholder {
      color: ${color.red};
    }
  ` : null}

  &:focus {
    outline: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(24)};
    margin-bottom: ${desktopVW(33)};
    height: ${desktopVW(166)};
    padding: ${desktopVW(12)};
  }
`

const StyledSubmit = styled.button`
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  height: ${mobileVW(65)};
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  bottom: 0;
  background-color: ${color.black};
  color: ${color.white};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(20)};
    height: ${desktopVW(65)};
  }
`

const SuccessMessage = styled.div`
  font-size: ${mobileVW(40)};
  line-height: ${mobileVW(44)};
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(40)};
    line-height: ${desktopVW(44)};
    padding: 0 ${desktopVW(60)};
  }
`

const InvalidMessage = styled(SuccessMessage)``

const AcceptTerms = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: ${mobileVW(16)};
  bottom: 0;

  label {
    font-size: ${mobileVW(10)};
    padding-bottom: ${mobileVW(2)};
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: ${mobileVW(20)};
    cursor: pointer;
    line-height: ${mobileVW(16)};
    display: inline-block;
    color: ${color.black};
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 1px solid ${color.black};
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 3px;
    height: 3px;
    background: ${color.black};
    position: absolute;
    top: 5.3px;
    left: 5.3px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  @media (min-width: ${desktopBreakpoint}) {
    left: 0;
    margin: ${desktopVW(16)} 0;

    label {
      font-size: ${desktopVW(8)};
      line-height: ${desktopVW(10)};
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      padding: 0 ${desktopVW(25)} ${desktopVW(2)} ${desktopVW(20)};
      line-height: ${desktopVW(14)};
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
    }
  }
`

const PrivacyLabel = styled.label`
  color: ${({ empty }) =>
    empty ? color.red : color.black}!important;
`

const StyledLink = styled(SiteLink)`
  ${underlineMultiLines}
  font-weight: bold;

  ${({ empty }) => empty ? `
    background-image: linear-gradient(${color.red}, ${color.red});
  ` : null}
`

export default ContactForm
