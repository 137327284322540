import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ContactForm from '../components/contact/ContactForm'

const Contact = ({
  data: {
    contentfulContactPage: {
      slug,
      seoTitle,
      seoDescription,
      contacts,
      contactStripText
    }
  }
}) => {
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      <ContactForm contacts={contacts} contactStripText={contactStripText} />
    </>
  )
}

export const PageQuery = graphql`
  query Contact($node_locale: String!) {
    contentfulContactPage(node_locale: { eq: $node_locale }) {
      seoTitle
      seoDescription
      ...contactPage
    }
  }
`

export default Contact
